import "./App.css";
import { UnoBoard } from "./features/uno-board/UnoBoard";
import { UnoWaitingRoom } from "./features/uno-waiting-room/UnoWaitingRoom";
import ErrorBoundary from "./shared/components/error-boundary/ErrorBoundary";
import { StoreProvider } from "./store/StoreProvider";

function App() {
  const gameOn = window.location.pathname.split("/").filter(Boolean).length;

  return (
    <ErrorBoundary fallback={<div />}>
      <div className="App">
        <div className="App-header">
          {gameOn ? (
            <StoreProvider>
              <UnoBoard />
            </StoreProvider>
          ) : (
            <UnoWaitingRoom />
          )}
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default App;

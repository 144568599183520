import { apiFetch } from "../../utils/api-fetch";
import { getGameId } from "../../utils/get-game-id";
import { appConfig } from "../app-config";

export const postPick = async () => {
  const gameId = getGameId();
  await apiFetch(`${appConfig.apiUrl}game/${gameId}/pick`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

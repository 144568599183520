import React, { useState } from "react";
import "./UnoWaitingRoom.css";
import { postCreate } from "../../store/connection/requests/post-create";
import { hasDuplicates } from "./utils";
import { setPlayerId } from "../../store/utils/player";

export const UnoWaitingRoom: React.FC = () => {
  const [gameName, setGameName] = useState("");
  const [error, setError] = useState("");
  const [players, setPlayers] = useState(["", "", "", "", ""]);

  const handlePlayerNameChange = (index: number, value: string) => {
    const updatedPlayers = [...players];
    updatedPlayers[index] = value;
    setPlayers(updatedPlayers);
  };

  const handleStartGame = () => {
    if (!gameName) {
      setError("Game name need to be set.");
      return;
    }
    if (hasDuplicates(players.filter(Boolean))) {
      setError("You cannot duplicate players.");
      return;
    }
    if (players.filter(Boolean).length < 2) {
      setError("You need to have at least 2 players.");
      return;
    }

    setError("");
    // Here you can handle starting the game logic
    console.log("Starting game with:", gameName, players);
    postCreate({ gameName, players })
      .then((res) => res.json())        
      .then((res) => {
        if (res.ok) {
          setPlayerId(res.userId);
          window.location.replace(gameName);
        } else setError(res.error);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="game-panel">
      <h2>Create UNO-like Game</h2>
      <div className="input-group">
        <label htmlFor="gameName">Game Name:</label>
        <input
          type="text"
          id="gameName"
          value={gameName}
          onChange={(e) => setGameName(e.target.value)}
        />
      </div>
      <div className="input-group">
        <label>Players:</label>
        {players.map((player, index) => (
          <input
            key={index}
            type="text"
            placeholder={`Player ${index + 1}`}
            value={player}
            onChange={(e) => handlePlayerNameChange(index, e.target.value)}
          />
        ))}
      </div>

      {error && <div className="error">{error}</div>}

      <button className="uno-button" onClick={handleStartGame}>
        Start Game
      </button>
    </div>
  );
};

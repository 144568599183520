import React, { useCallback, useState } from "react";
import { Card, CardProps } from "../../shared/components/card/Card";
import { postSubmit } from "../../store/connection/requests/post-submit";
import { UnoModal } from "../uno-modal/UnoModal";

interface UnoCardProps extends CardProps {
  id: string;
  canPlayCard: boolean;
}
export const UnoCard = (props: UnoCardProps) => {
  const [askForColor, setAskForColor] = useState(false);
  const onSubmit = useCallback(() => {
    if (["+4", "֎"].includes(props.content)) {
      setAskForColor(true);
    } else postSubmit({ cardId: props.id });
  }, [props.content, props.id]);

  const onColorPick = (temporaryColor: string) => {
    setAskForColor(false);
    postSubmit({ cardId: props.id, temporaryColor });
  };

  return (
    <React.Fragment>
      {askForColor && <UnoModal onColorPick={onColorPick} />}
      <Card
        {...props}
        onClick={props.canPlayCard ? onSubmit : undefined}
        disabled={!props.canPlayCard}
      />
    </React.Fragment>
  );
};

import { useCallback, useReducer } from "react";
import { IUnoGame } from "../shared/model/uno-game";
import { canPlayCard } from "./utils/can-play-card";

export interface StoreState {
  state?: IUnoGame;
}

interface UpdateAction {
  type: "update";
  payload: IUnoGame;
}

type Action = UpdateAction;

const initialState: StoreState = {};

const reducer = (state: StoreState, action: Action): StoreState => {
  switch (action.type) {
    case "update":
      action.payload.player.cards.forEach(card => {
        card.canPlayCard = canPlayCard(action.payload,card);
      })
      return {
        ...state,
        state: action.payload,
      };
    default:
      return state;
  }
};

export const useStoreState = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const update = useCallback((payload: IUnoGame) => {
    dispatch({ type: "update", payload });
  }, [dispatch]);

  return {
    state,
    update,
  };
};

import React, { PropsWithChildren } from "react";
import "./CardStack.css";

interface CardStackProps {}
export const CardStack = (props: PropsWithChildren<CardStackProps>) => {
  const { children } = props;
  let comp = children;
  if (Array.isArray(children)) {
    const deckWidth = 60 * (children.length - 1) + 220;
    const screenWidth = document.body.clientWidth;
    comp = children.map((child, idx) => (
      <div
        key={idx}
        className="game-card-stack-item"
        style={{
          left: idx * 60 + Math.max(0, (screenWidth - deckWidth) / 2),
        }}
      >
        {child}
      </div>
    ));
  }

  return <div className="game-card-stack">{comp}</div>;
};

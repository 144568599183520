export function hasDuplicates<T>(array: T[]) {
  // Use a Set to keep track of unique elements we've seen so far
  let seen = new Set();

  for (let i = 0; i < array.length; i++) {
    // If the element is already in the Set, it's a duplicate
    if (seen.has(array[i])) {
      return true;
    }
    // Otherwise, add the element to the Set
    seen.add(array[i]);
  }

  // If we finish the loop without finding duplicates
  return false;
}

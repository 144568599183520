import { useCallback } from "react";
import { postPick } from "../../store/connection/requests/post-pick";

export const UnoPickCard = () => {
  const pickCard = useCallback(() => {
    postPick();
  }, []);
  return (
    <button className="uno-button game-pick-card" onClick={pickCard}>
      Pick card
    </button>
  );
};

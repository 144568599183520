import React from "react";
import "./Card.css";

export interface CardProps {
  background: React.CSSProperties["background"];
  content: string;
  onClick?: () => void;
  disabled?: boolean;
}
export const Card = (props: CardProps) => {
  const { background, content, onClick, disabled } = props;
  const className = ["game-card-border"];
  if (disabled) {
    className.push("game-card-border-disabled");
  }
  return (
    <div className={className.join(" ")} onClick={onClick}>
      <div
        className="game-card"
        style={{
          background: background === "yellow" ? "#FFEA00" : background,
        }}
      >
        <div className="game-card-small">{content}</div>
        {content}
      </div>
    </div>
  );
};

import { apiFetch } from "../../utils/api-fetch";
import { appConfig } from "../app-config";

export const postCreate = async (data: {
  gameName: string;
  players: string[];
}) => {
  return await apiFetch(`${appConfig.apiUrl}game/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};

import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useWebSocket } from "./connection/use-websocket";
import { useApi } from "./connection/use-api";
import { getInit } from "./connection/requests/get-init";
import { StoreState, useStoreState } from "./use-store-state";
import { UnoPickPlayer } from "../features/uno-pick-player/UnoPickPlayer";

/**
 * App use single store in this provider, if need to be more performant just make more stores.
 */
export interface AppStore extends StoreState {}

const StoreContext = createContext<AppStore>({});

export const StoreProvider = (props: PropsWithChildren) => {
  const { data, refetch } = useApi(getInit);
  const socket = useWebSocket();

  const { state, update } = useStoreState();
  const value = useMemo(() => {
    return {
      ...state,
    };
  }, [state]);

  useEffect(() => {
    if (data?.player) update(data);
  }, [data, update]);

  useEffect(() => {
    if (!socket) return;
    socket.onmessage = () => {
      refetch();
    };
  }, [refetch, socket]);

  if (!data?.player) {
    return <UnoPickPlayer partialGame={data} />;
  }

  return (
    <StoreContext.Provider value={value}>
      {state.state && props.children}
    </StoreContext.Provider>
  );
};

export const useStore = () => useContext(StoreContext) as Required<AppStore>;

import React from "react";
import ReactDOM from "react-dom";
import "./UnoModal.css";

interface UnoModalProps {
  onColorPick: (color: string) => void;
}

export const UnoModal = ({ onColorPick }: UnoModalProps) => {
  return ReactDOM.createPortal(
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Pick a Color</h2>
        <div className="color-options">
          <button
            className="color-button red"
            onClick={() => onColorPick("red")}
          >
            Red
          </button>
          <button
            className="color-button blue"
            onClick={() => onColorPick("blue")}
          >
            Blue
          </button>
          <button
            className="color-button green"
            onClick={() => onColorPick("green")}
          >
            Green
          </button>
          <button
            className="color-button yellow"
            onClick={() => onColorPick("yellow")}
          >
            Yellow
          </button>
        </div>
      </div>
    </div>,
    document.body,
  );
};

import { IUnoCard, IUnoGame } from "../../shared/model/uno-game";

export const canPlayCard = (game: IUnoGame, card: IUnoCard) => {
  return (
    game.stack.color === card.color ||
    game.stack.value === card.value ||
    card.color === "black" || 
    game.temporaryColor === card.color
  );
};

import { useEffect, useState } from "react";
import { IUnoGame } from "../../shared/model/uno-game";
import { setPlayerId } from "../../store/utils/player";

interface UnoPickPlayerProps {
  partialGame?: IUnoGame | null;
}

export const UnoPickPlayer = (props: UnoPickPlayerProps) => {
  const { partialGame } = props;
  const [playerName, setPlayerName] = useState("");
  const [gameName, setGameName] = useState(partialGame?.id);
  const [error, setError] = useState("");

  useEffect(() => {
    if (!partialGame) return;
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("invite");
    const player = partialGame.opponents.find((x) => x.id === id);
    if (!player?.name) {
      setError("There is no player of id " + id);
      return;
    }
    setPlayerName(player?.name);
  }, [partialGame]);

  useEffect(() => {
    if (!partialGame) return;
    setGameName(partialGame.id);
  }, [partialGame]);

  const handleStartGame = () => {
    if (partialGame?.opponents) {
      const player = partialGame.opponents.find((x) => x.name === playerName);
      if (player) {
        setPlayerId(player.id);
        window.location.reload();
      } else setError("You need to pick correct player");
    }
  };

  return (
    <div className="game-panel">
      <h2>Join UNO-like Game</h2>
      <div className="input-group">
        <label htmlFor="gameName">Game Name:</label>
        <input
          type="text"
          id="gameName"
          value={gameName}
          disabled
          onChange={(e) => setGameName(e.target.value)}
        />
      </div>
      <div className="input-group flex">
        <label>Who are you:</label>
        <input
          type="text"
          id="playerName"
          value={playerName}
          onChange={(e) => setPlayerName(e.target.value)}
        />

        {error && <div className="error">{error}</div>}

        <button className="uno-button" onClick={handleStartGame}>
          Start Game
        </button>
      </div>
    </div>
  );
};

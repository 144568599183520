import { useState, useEffect, useCallback, useRef } from "react";

type ApiResponse<Data> = {
  data: Data | null;
  isLoading: boolean;
  error: Error | null;
  refetch: () => Promise<void>;
};

type ApiCallback<Data> = () => Promise<Data>;

interface UseApiOption {
  skip?: boolean;
}

export function useApi<Data>(
  apiCallback: ApiCallback<Data>,
  options?: UseApiOption
): ApiResponse<Data> {
  const [data, setData] = useState<Data | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const optionsRef = useRef(options);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const result = await apiCallback();
      setData(result);
    } catch (err) {
      console.error(`${err}`, { err });
      if (err instanceof Error) {
        setError(err);
      }
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading, setError, apiCallback]);

  useEffect(() => {
    if (!optionsRef.current?.skip) fetchData();
  }, [fetchData]);

  return { data, isLoading, error, refetch: fetchData };
}

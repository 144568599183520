import { getPlayerId } from "./player";

// Create a wrapper function for fetch
export function apiFetch(
  url: string,
  options: RequestInit = {},
): Promise<Response> {
  // Merge the default headers with any headers provided in options
  options.headers = {
    "user-id": getPlayerId() ?? "",
    ...options.headers,
  };

  // Call the original fetch with the modified options
  return fetch(url, options);
}

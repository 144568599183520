export async function requestClipboardWritePermission() {
  try {
    // Check if the Permissions API is supported
    if (navigator.permissions) {
      // Query the permission status
      const permissionStatus = await navigator.permissions.query({
        name: "clipboard-write" as any,
      });
      if (
        permissionStatus.state === "granted" ||
        permissionStatus.state === "prompt"
      ) {
        return true;
      }
    }
  } catch (error) {
    console.error("Error checking clipboard-write permission:", error);
  }
  return false;
}
export async function copyTextToClipboard(text: string) {
  const hasPermission = await requestClipboardWritePermission();
  if (hasPermission) {
    try {
      // Write text to clipboard
      await navigator.clipboard.writeText(text);
      console.log("Text copied to clipboard:", text);
    } catch (error) {
      console.error("Error writing to clipboard:", error);
    }
  } else {
    console.error("Clipboard-write permission denied");
  }
}

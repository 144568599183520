import React from "react";
import "./UnoOponents.css";
import { useStore } from "../../store/StoreProvider";
import { copyTextToClipboard } from "./utils";
interface UnoOponentsProps {}

export const UnoOponents = React.memo((props: UnoOponentsProps) => {
  const { state } = useStore();
  const list = state.opponents.map((op) => (
    <UnoOponentsRow
      key={op.id}
      row={op}
      active={op.id === state.currentPlayerId}
    />
  ));

  return (
    <div className="game-opponents">
      <UnoOponentsRow
        row={{
          id: -1,
          name: "Name",
          cards: "C.",
          points: "P.",
        }}
      />
      {list}
    </div>
  );
});

interface UnoOponentsCellProps {
  value: string | number;
  size: number;
  className?: string;
  onClick?: () => void;
}
const UnoOponentsCell = (props: UnoOponentsCellProps) => {
  return (
    <div
      className={"game-opponents-cell " + props.className ?? ""}
      style={{ width: props.size * 60 }}
      onClick={props.onClick}
    >
      {props.value}
    </div>
  );
};

interface UnoOponentsRowProps {
  row: any;
  active?: boolean;
}

const UnoOponentsRow = (props: UnoOponentsRowProps) => {
  const { row, active } = props;
  const className = ["game-opponents-row"];
  if (active) {
    className.push("game-opponents-row-active");
  }
  return (
    <div key={row.id} className={className.join(" ")}>
      <UnoOponentsCell
        value={row.name}
        size={3}
        className={row.id === -1 ? undefined : "name-cell"}
        onClick={() => {
          copyTextToClipboard(window.location.href + "?invite=" + row.id);
        }}
      />
      <UnoOponentsCell value={row.cards} size={1} />
      <UnoOponentsCell value={row.points} size={1} />
    </div>
  );
};

import { apiFetch } from "../../utils/api-fetch";
import { getGameId } from "../../utils/get-game-id";
import { appConfig } from "../app-config";

export const postSubmit = async (data: {
  cardId: string;
  temporaryColor?: string;
}) => {
  const gameId = getGameId();
  await apiFetch(`${appConfig.apiUrl}game/${gameId}/submit`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};
